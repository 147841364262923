// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./channels/consumer.js"
import "./channels/upgrade_app_channel.js"
import "./controllers"


// // window.$ = window.jQuery = require("jquery");
// import "bootstrap/dist/js/bootstrap.bundle.js"

// import  jQuery  from "jquery"
// let $ = windowimport  jQuery  from "jquery"

// let $ = window.$ = window.jQuery = jQuery
// .$ = window.jQuery = jQuery

import * as bootstrap from "bootstrap"
import "chartkick/chart.js"


document.addEventListener("turbolinks:load", () => {
    $('[data-bs-toggle="tooltip"]').tooltip();
    $('[data-bs-toggle="popover"]').popover();
    $('[data-bs-toggle="dropdown"]').dropdown();
})

