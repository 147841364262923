import {Controller} from "@hotwired/stimulus"

class Queue {
    constructor() {
        this.elements = {};
        this.head = 0;
        this.tail = 0;
        this.maxConcurrents = 10;
        this.concurrents = 0;
    }
    enqueue(element) {
        this.elements[this.tail] = element;
        this.tail++;
    }
    dequeue() {
        const item = this.elements[this.head];
        delete this.elements[this.head];
        this.head++;
        return item;
    }
    peek() {
        return this.elements[this.head];
    }
    canRun () {
        return this.concurrents < this.maxConcurrents;
    }
    increaseConcurrents () {
        this.concurrents++;
    }
    decreaseConcurrents () {
        this.concurrents--;
    }
    get length() {
        return this.tail - this.head;
    }
    get isEmpty() {
        return this.length === 0;
    }
}

export default class extends Controller {
    static values = {
        url: String,
        force: Boolean,
        ids: String,
        max_concurrent: 4,
        concurrent: 0,
    }

    static targets = ["success", "error", "total"]

    queue;
    _success = 0;
    _errors = 0;

    connect() {
        let appIds = this.idsValue.split(',');
        this.queue = new Queue();
        while (appIds.length > 0) {
            this.queue.enqueue(appIds.shift());
        }

        this.run();
    }

    run() {
        while (!this.queue.isEmpty && this.queue.canRun()) {
            this.queue.increaseConcurrents();
            this.upgradeApp(this.queue.dequeue(), () => { this.queue.decreaseConcurrents(); });
        }
        if (!this.queue.canRun() && !this.queue.isEmpty) {
            setTimeout(() => { this.run(); }, 1000);
        }
    }

    updateCounters() {
        document.getElementById("successCounter").textContent = this._success;
        document.getElementById("errorCounter").textContent = this._errors;
    }

    updateErrors(response) {
        // @TODO
    }

    upgradeApp = (id, callbackDone) => {
        let url = this.urlValue.replace(/\/(applis)\/\d+/g, "/$1/" + id).replace(/force=false/, "force=" + this.forceValue);

        fetch(url, {
                method: 'PATCH',
                headers: {
                    Accept: "text/vnd.turbo-stream.html",
                }
            }).then((response) => {
                if (response.ok) {
                    this._success++;
                } else {
                    this._errors++;
                    response.clone().text().then((data) => {
                        this.updateErrors(id, data);
                    });
                }
                callbackDone();
                this.updateCounters();
                return response.text();
            }).then(html => Turbo.renderStreamMessage(html));
    }
}
