// app/javascript/controllers/migrate_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    confirm(event) {
        let confirmed = confirm("Merci de confirmer cette action?");
        if (!confirmed) {
            event.preventDefault();
        }
    }
}
