import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    // console.log("CONNECTION Cell");
  }

  show() {
   // alert(this.contentTarget.innerHTML)
    // const modal = document.getElementById("modal");
    const modal = new bootstrap.Modal(document.getElementById('modal'))
    modal.toggle();


    const modalContent = document.getElementById("modal-content");
    modalContent.innerHTML = "<pre>" + this.contentTarget.innerHTML + "</pre>";
  }
}
