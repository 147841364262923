import consumer from "./consumer"
import * as bootstrap from "bootstrap"

// function who truncate a string
function truncate(str, n){
  if (str == null) return "";

  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

consumer.subscriptions.create('ApplicationCable::UpgradeAppChannel', {
  initialized() {
    console.log("Initialized  le channel UpgradeAppChannel")
  },

  // Called when the subscription is rejected by the server.
  rejected() {
    console.log("Rejected le channel UpgradeAppChannel")
  },

  received(data) {
    console.log(data);

    const id = `upgrade_log_id_${data.log_id}`
    console.log(id);

    const logRow = document.getElementById(id);

    if (logRow == null) {
      console.log(logRow);
      console.log("log non trouvé sur la page courante.");
      this.updateToast(data.log_id, data.app_id, data.context);
      return;
    }

    const statusJob         = logRow.querySelector('.upgrade-logs--status-job');
    const statusMigration   = logRow.querySelector('.upgrade-logs--status-migration');
    const statusSeed        = logRow.querySelector('.upgrade-logs--status-seed');
    const statusPermission  = logRow.querySelector('.upgrade-logs--status-permission');
    const statusSearchIndex = logRow.querySelector('.upgrade-logs--status-search-index');
    const statusSyncroUsers = logRow.querySelector('.upgrade-logs--status-syncro-users');

    const emptyClass = "bg-transparent";

    statusJob?.classList.remove(emptyClass);
    statusMigration?.classList.remove(emptyClass);
    statusSeed?.classList.remove(emptyClass);
    statusPermission?.classList.remove(emptyClass);
    statusSearchIndex?.classList.remove(emptyClass);
    statusSyncroUsers?.classList.remove(emptyClass);

    statusJob?.classList.add(data.status_job_class);
    if (statusJob) statusJob.textContent = truncate(data.log_job, 120);

    statusMigration?.classList.add(data.status_migration_class);
    if (statusMigration) statusMigration.textContent = truncate(data.log_migration, 20);

    statusSeed?.classList.add(data.status_seed_class);
    if (statusSeed) statusSeed.textContent = truncate(data.log_seed, 20);

    statusPermission?.classList.add(data.status_permission_class); 
    if (statusPermission) statusPermission.textContent = truncate(data.log_permission, 20);

    statusSearchIndex?.classList.add(data.status_search_index_class);
    if (statusSearchIndex) statusSearchIndex.textContent = truncate(data.log_search_index, 20);

    statusSyncroUsers?.classList.add(data.status_syncro_users_class);
    if (statusSyncroUsers) statusSyncroUsers.textContent = truncate(data.log_syncro_users, 20);
  },

  updateToast(logId, appId, context) {
    const title = `Fin job : ${context}`;
    const content = `Le job ${logId} sur l'application ${appId} est terminé.`;
    const toast = document.getElementById("liveToast");

    toast.classList.add("show");
    toast.querySelector('.toast-header strong').textContent = title;
    toast.querySelector('.toast-body').textContent = content;

    setTimeout(() => {
      toast.classList.remove("show");
    }, 10000);
  },

  connected() {
    console.log("Connected sur le channel UpgradeAppChannel")
  },

  disconnected() {
    console.log("Disconnected sur le channel UpgradeAppChannel")
  },

});

